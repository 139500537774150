body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/*======================================== Workspace =====================================*/

.wksp_textfield {
  display: flex;
  justify-content: flex-end;
  
}
.wksp_uprbx{
  width: 50%;
  text-align: center;
  right: 0px;
  margin-top: 40px;
}

.wksp_txtarea {
  padding-top: 8px;
  justify-content: center;
  text-align: center;
  margin: 10px;
  background-color: white;
  width: 300px;
  height: 40px;
  border: 1px solid blue;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  outline: 0;
 
}
.wksp_txtarea1{
  width: 150px;
}
.wksp_txtarea h4 {
  
    text-align: left;
    margin-left: 120px;
    color: #325799;
    margin-bottom: 5px;
    margin-top: 25px;
  
}
.wksp_textbtn{

  
  width: 30px;
  height: 30px;
  margin: 5px;
  border: none;
  min-width: 0px;
  border-radius: 50%;
}

.wksp_uprbx h3 {
  outline: none;
}

#outlined-basic {
  width: 280px;
  text-align: center;
  background-color: white;
  height: 10px;
  border-radius: 5px;
}

.btnwksp {
  border: none;
  font-size: 15px;
  font-weight: bold;
  width: 300px;
  padding: 15px;
  margin: 20px;
  border-radius: 50px;
  color: white;
  background-color: #2475FC;
}
.bx-dgndp {
  background-color: white;

  width: 280px;
  border: 4px dotted rgb(224, 217, 204);
  margin-left: auto;
  margin-right: auto;
}
.dropzone {
  /* padding: 1rem; */
  margin: 10px;
  border-radius: 5px;
  max-height: 100px;
}
.upld_file {
  color: black;
    padding: 0;
    margin: 0;
    list-style: outside none;


}
.upld_file > li{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
/*============================== SignIn ===========================*/



#inputID{
  
  -webkit-appearance: none;
  padding: 15px;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
  background-color: white;
  width: 300px;
  /* height: 40px; */
  /* border: 1px solid blue; */
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  outline: 0;
  border: 0;
  box-shadow: 0px 1px 1px 2px rgba(0,0,0,0.2); 
  -webkit-box-shadow: 0px 1px 1px 2px rgba(0,0,0,0.2); 

}
#inputID::placeholder {
  color: #888888;
  opacity: 0.5;
}
.log_in {
  display: flex;
  justify-content: flex-end;
}
.log_inleft{
  width: 60%;
  height: 100vh;
  background-color: #F0F3FA;
}
.log_in_box {
  height: 90vh;
  width: 50%;
  text-align: center;
  right: 0px;
  /* margin-top: 40px; */
}
.log_in_box h1{
 color: #0F0F0F;
 font-family: Roboto;
 font-weight:400;
 margin-top: 30px;
  margin-bottom: 30px;
}
.log_in_box p{
  margin-top: 0px;
  margin-bottom: 35px;
  color: #888888;
}
.login_size {
  padding: 10px;
  flex-direction: row;
  display: flex;
  margin-bottom: 20px;
  width: 300px;
  
  /* border: 1px solid black; */
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  box-shadow: 0px 1px 1px 2px rgba(0,0,0,0.2);
}
#or
  {
    padding: 25px;
    position: relative;
    width: 300px;
    margin: auto;
    line-height: 0px;
    text-align: center;
    font-size: bold;
  }
  
  #or::before,
  #or::after {
    position: absolute;
    width: 150px;
    height: 1px;
    
    top: 24px;
    
    background-color: #aaa;
    
    content: '';
  }
  
  #or::before {
    left: 0;
  }
  
  #or::after {
    right: 0;
  }

.login_lnkdln{
  padding: 10px;
  flex-direction: row;
  display: flex;
  margin-bottom: 0px;
  background-color:#0E87CB;
  width: 300px;
  /* border: 1px solid blue; */
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
}
.login_size img{
  width: 12%;
}
.login_lnkdln img{
  width: 12%;
  border-radius: 8px;
}
.login_sizetxt {
  text-align: center;
  background-color: white;
  width: 300px;
  padding: 5px;
  border: 0;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  outline: none;
}



.login_link{
color: white;
font-weight: 400;
text-decoration: none;
}
.para_bottom_img{
  padding: 5px;
  width: 30;
  height: 30px;
 }
 .para_bottom p{
  margin: 0px;
  padding: 5px;
  font-size: 13px;
  font-family: 'POPPINS';
  font-weight: 500;
  color: #888888
 }
 
 
 .btnwksplogin {
  border: none;
  font-size: 15px;
  font-weight: bold;
  width: 320px;
  
  padding: 15px;
  margin-bottom: 30px;
  border-radius: 50px;
  color: white;
  background-color: #2475FC;
}

/*======================================= SignUp ===============================*/
.sign_up {
  display: flex;
  justify-content: flex-end;
}
.sign_up_box {
  width: 50%;
  text-align: center;
  right: 0px;
  
}
.sign_up_box p{
  margin-top: 0px;
  margin-bottom: 35px;
  color: #888888;
}
.sign_up_box h1{
  color: #0F0F0F;
  font-family: Roboto;
  font-weight:400;
   margin: 30px;
}
.signup_size {
  padding: 10px;
  flex-direction: row;
  display: flex;
  margin-bottom: 20px;
  background-color: white;
  width: 300px;
  
  /* border: 1px solid black; */
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  box-shadow: 0px 1px 1px 2px rgba(0,0,0,0.2);

}
.signup_size img{
  width: 12%;
  

}
.signup_sizetxt {
  text-align: center;
  background-color: white;
  width: 300px;
  padding: 5px;
  border:none;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  outline: none;
}
.btnwksp_sgnup {
  border: none;
  font-size: 15px;
  font-weight: bold;
  width: 320px;
  padding: 15px;
  margin-bottom: 30px;
  border-radius: 50px;
  color: white;
  background-color: #2475FC;
}
.signup_link { 
  color:white;
  font-weight: 400;
  text-decoration: none; 
}
.para_bottom{
  
  padding: 8px;
  text-align: left;
  display: flex;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  background-color:#F5F5F5;
  border-radius: 5px;
  margin-bottom: 20px;
}

.para_bottom_img{ 
 width: 30px;
 height: 30px;
 }
 .para_bottom_link{
  color: black;
  font-weight: bold;
 text-transform: capitalize;
 font-size: 16px;
 }
 
/*=================================== SignInMagic =============================*/
.sign_in_magic {
  display: flex;
  justify-content: flex-end;
}

.sign_in_magic_box {
  width: 50%;
  text-align: center;
  right: 0px;
  margin-top: 40px;
  height: 90vh;
}
.magic_boxmain{
  display: flex;
    flex-direction: row;
    justify-content: center;
    
  
}
.magic_boxmain h1{
  font-family: Roboto;
  font-weight: 400;
  color: #325799;
}
.term_policy{
  
 padding: 0px;
  height: 0vh;
}

/* ================================= verfy_email ===============================*/
.verify_email {
  display: flex;
  justify-content: flex-end;
  
}
.verify_emailbox {
  width: 50%;
  text-align: center;
  right: 0px;
  margin-top: 40px;
}
.verify_emailbox_main {
  width: 390px;
 margin-left: auto;
 margin-right: auto;
}
.verify_emailbox_main input {
  background: "linear-gradient(-225deg, ${i.color} 0%, ${i.color1} 56%, #FFBAC3 100%)";
  width: 50px;
  height: 50px;
  font-size: 32;
  text-align: center;
  min-width: 0px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.term_policy{
    
    display: flex;
    text-align: center;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    margin-top: auto;
    height: 0vh;
    
}
/*======================welcome-back=================*/
.wlcm_back {
  /* background-image: linear-gradient(-225deg, #2CD8D5 0%, #C5C1FF 56%, #FFBAC3 100%);  height: 100vh; */
  display: flex;
  justify-content: flex-end;
}

.wlcm_back_box {
  width: 50%;
  text-align: center;
  right: 0px;
  margin-top: 40px;
}
.wlcm_uprboxmain{
  display: flex;
    flex-direction: row;
    justify-content: center;
}
.wlcm_uprboxmain h1{
  
  font-family: Roboto;
  font-weight: 400px;
  color: #325799;
}
.multiple_account {
  padding: 10px;
  flex-direction: row;
  display: flex;
  margin: 15px;
  background-color: white;
  width: 300px;
  border: 1px solid blue;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
}
.multiple_accounttxt {
  text-align: center;
  background-color: white;
  width: 250px;
  padding: 5px;

  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  outline: none;
}
.multiple_accountbox {
  border: 1px solid purple;
  display: flex;
  padding: 4px 6px;
  height: 26px;
  margin-top: 4px;
  flex-direction: row;
  justify-content: space-evenly;
  width: 50px;
  font-weight: bold;
  color: purple;
  
}
.multiple_accountbox:hover{
  color: #fff;
  background-color: purple;
}

/* ================= responsive ===================== */

@media screen and (max-width: 1023px) {
  .wksp_uprbx {
    width: 100%;
    justify-content: center;
  }
  .sign_up_box {
    width: 100%;
  }
  .log_in_box{
    width: 100%;
  }
  .sign_in_magic_box{
    width: 100%;
  }
  .wlcm_back_box {
    width: 100%;
  }
  .verify_emailbox{
    width: 100%;
  }
  .log_inleft{
    display: none;

  }
}



@media screen and (max-width: 767px) {
  .wksp_textfield {
    width: 100%;
  }

  .sign_up_box {
    width: 100%;
  }
  .log_in_box{
    width: 100%;
  }
  .sign_in_magic_box{
    width: 100%;
  }
  .wlcm_back_box {
    width: 100%;
  }
  .verify_emailbox{
    width: 100%;
  }
}


@media screen and (max-width: 767px){
  .log_inleft{
    display: none;

  }
  /* .wksp_uprbx{
    width: 0%;
  } */
}
@media screen and (max-width: 1023px){
  .log_inleft{
    width: 0%;

  }
}